import { Heading, Text } from "@chakra-ui/react";
import type { NextPage } from "next";

const Home: NextPage = () => {
  return (
    <>
      <Heading textAlign={"center"} p={2}>
        Welcome to Limitless!
      </Heading>
      <Text p={2} align={"center"}>
        We are passionate about personal finance, and want to enable you to
        manage your finances easily and intuitively!
      </Text>
      <Text p={2} align={"center"}>
        <b>Owning your data is your right.</b> We will never collect uneccessary
        information, and will never sell data.
      </Text>
      <Text p={2} align={"center"}>
        To make suggestions for features and report issues, email Forrest —
        forrest@limitlesslife.app.
      </Text>
    </>
  );
  // return (
  //   <>
  //     <Box p={4}>
  //       <Grid
  //         templateRows="repeat(2, 1fr)"
  //         templateColumns="repeat(2, 1fr)"
  //         gap={4}
  //       >
  //         <GridItem rowSpan={1} colSpan={1}>
  //           <Heading p={2} align={"center"}>
  //             Welcome to Limitless!
  //           </Heading>
  //           <Text p={2} align={"center"}>
  //             We are passionate about personal finance, and want to enable you
  //             to manage your finances easily and intuitively!
  //           </Text>
  //           <Text p={2} align={"center"}>
  //             <b>Owning your data is your right.</b> We will never collect
  //             uneccessary data, and will never sell data.
  //           </Text>
  //           <Text p={2} align={"center"}>
  //             To make suggestions for features and report issues, email Forrest
  //             — forrest@limitlesslife.app.
  //           </Text>
  //         </GridItem>
  //         <GridItem colSpan={2} bg="papayawhip" />
  //         <GridItem colSpan={2} bg="papayawhip" />
  //         <GridItem colSpan={4} bg="tomato" />
  //       </Grid>
  //     </Box>
  //     <h1>This is my index.</h1>
  //   </>
  // );
};

export default Home;
